<template>
  <div>
    <div v-bind:style="{ background: color }"></div>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      required: true
    }
  },
  data() {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
div {
  div {
    //background: #7DD5F0;
    border-radius: 50%;
    height: 0.5rem;
    width: 0.5rem;
    display: inline-flex;
  }
}
</style>