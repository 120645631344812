<template>
  <div class="about">
    <b-container>
      <b-row>
        <b-col>
          <div class="back">
            <div 
              class="back-link"
              v-on:click="navBack()"
            >
              <BIconArrowLeftShort /> Go back to the map
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="title">
            About the Startup Cities Map
          </div>
        </b-col>
      </b-row>
      <b-row 
        
      >
        <b-col sm="7" order="12" order-sm="1">
          <b-row>
            <b-col class="content-column">
              <section id="what-are-startup-cities" ref="what-are-startup-cities">
                <h3>What are startup cities?</h3>
                <p class="about-text">
                  In May of 2021, Balaji Srinivasan, author of <a href="https://www.amazon.com/Network-State-How-Start-Country-ebook/dp/B09VPKZR3G" target="__blank">The Network State</a>, described and divided startup cities as follows:
                </p>
                <p class="about-text">
                  <TypeSimple color="#7DD5F0"></TypeSimple> A city where startups happen, like San Francisco used to be
                </p>
                <p class="about-text">
                  <TypeSimple color="#D29AF5"></TypeSimple> A city that acts like a startup, like Miami, Singapore, Dubai
                </p>
                <p class="about-text">
                  <TypeSimple color="#81D17A"></TypeSimple> A city that is a startup itself, like <a href="http://culdesac.com" target="__blank">Culdesac Tempe</a> and <a href="http://prospera.hn" target="__blank">Prospera</a>
                </p>
                <p class="about-text">
                  No worldwide or industry consensus on how to define a startup city, especially as it can mean many different focuses.
                  In a short period of time, its meaning has evolved - initially, a startup city was defined as "cities that have a lot of innovation or startups" 
                  However, with the recent rise of the charter city movement, we also have new city projects that act like a startup.
                </p>
                <p class="about-text">
                  Silicon Valley Entrepreneurs are looking at cities as their next product. VCs like <a href="https://www.pronomos.vc/" target="__blank">Pronomos Capital</a> are focusing on this new industry, partnering with citizens, states, and developers to build new cities and improve governance with better laws. 
                </p>
                <p class="about-text">
                  As the movement grows and new projects begin to emerge, both Balaji and the Adrianople Group decided to create a tool that will help visually map out this progress. This is how the Startup Cities Map was born. 
                </p>
              </section>
              <div id="methodology" ref="methodology" class="anchor-element"></div>
              <section>
                <h2>
                  Methodology 
                </h2>
                <p class="about-text">
                  <TypeSimple color="#7DD5F0"/> For Type 1: A city where startups happen, like San Francisco used to be.
                </p>
                <p class="about-text">
                  For the first definition, we are looking at established cities that have an ecosystem that fosters and promotes innovation and the creation of startups. For this list, we are basing our dataset on the Top 30 And Runners-Up cities from the <a href="https://startupgenome.com/report/gser2021" target="__blank">Global Startup Ecosystem Ranking (2017-2021)</a> made by <a>Startup Genome</a>. 
                </p>
                <p class="about-text">
                  <TypeSimple color="#D29AF5"/> For Type 2: A city that acts like a startup, like Miami, Singapore, Dubai
                </p>
                <p class="about-text">
                  For the second definition, we are looking at established cities that implement startup and entrepreneurial elements within their administration. We are basing this dataset off the <a href="https://www.innovation-cities.com/" target="__blnak">“The Innovation Cities™ Index”</a>. They ranked the top 100 most innovative cities out of 500 cities, measuring 162 indicators, and over 1000 data points. We selected the top 50 cities. 
                </p>
                <p class="about-text">
                  <TypeSimple color="#81D17A"/> For Type 3: A city that is a startup itself, like <a href="https://www.culdesac.com" target="__blnak">Culdesac Tempe</a> and <a href="https://www.prospera.hn" target="__blnak">Prospera</a>.
                </p>
                <p class="about-text">
                  For the third definition, we are looking at new cities that are master-planned, have a high degree of innovation, and are a startup in themselves. We created our own criteria and compiled a database of over 80 projects that we then filtered through.
                </p>
                <p class="about-text">
                  For projects to be considered within this definition, they should include a minimum of 5 out of the 7 following components:  
                </p>
                <ul>
                  <li>
                    For-profit (required)
                  </li>
                  <li>
                    Administratively defined boundaries
                  </li>
                  <li>
                    High-Degree of Innovation (required)
                  </li>
                  <li>
                    Master Planned
                  </li>
                  <li>
                    Residential Zone (required)
                  </li>
                  <li>
                    Commercial Zone
                  </li>
                  <li>
                    School
                  </li>
                </ul>
                <p class="about-text disclaimer">
                  The cities are listed under the de facto authority which controls it. Adrianople Group <b>does not take any position</b> on territorial disputes or the independence of partially-recognized states.
                </p>
              </section>
              <div id="submit-new-projects" ref="submit-new-projects" class="anchor-element"></div>
              <section>
                <h2>
                  Submit new projects
                </h2>
                <p class="about-text">
                  Do you know of a city or project that you think should be included on this map? Submit the project using the form below. <a href="https://airtable.com/shrXdbRpBcLS01WD9" target="__blank">Submit project here</a>
                </p>
              </section>
              <div id="how-to-use-this-map" ref="how-to-use-this-map" class="anchor-element"></div>
              <section>
                <h2>How to use this map</h2>
                <h3>Color Keys</h3>
                <p class="about-text">These are the colors displayed on the map:</p>
                <p class="about-text"><TypeSimple color="#7DD5F0"/> <b>Type 1:</b> Established cities that have an ecosystem that fosters and promotes innovation and the creation of startups.</p>
                <p class="about-text"><TypeSimple color="#D29AF5"/> <b>Type 2:</b> Established cities that implement startup and entreprenurial elements within thier administration.</p>
                <p class="about-text"><TypeCombo innerColor="#7DD5F0" outerColor="#D29AF5"></TypeCombo> <b>Type 1 and 2:</b> Established cities that fall within Type 1 and Type 2 simultaneously.</p>
                <p class="about-text"><TypeSimple color="#81D17A"/> <b>Type 3:</b> New cities that are master-planned, have a high degree of innovation, and are startups themselves.</p>
                <br/>
                <h3>Toggle Bar</h3>
                <p class="about-text">Tapping any of the following "types" will hide or unhide all of the cities under that type. This will allow you to customize your view and select only the type of projects you want to display.</p> 
                <div>
                  <b-img :src="toggleBarImage"></b-img>
                </div>
              </section>
              <div id="about-the-adrianople-group" ref="about-the-adrianople-group" class="anchor-element"></div>
              <section>
                <h2>About the Adrianople Group</h2>
                <p class="about-text">The Adrianople Group is a research and advisory firm focused on educating the world about Special Economic Zones (SEZs). Leading organizations including McKinsey & Company, PwC, and World Wildlife Foundation rely on the company's data to make impactful decisions. The Adrianople Group's data on SEZs provide geopolitical market research and business intelligence solution to investors, government officials, academics and all those interested in SEZs.</p>
                <p class="about-text">The Adrianople Group built the first map of Startup Cities, after launching the first complete map of all Special Economic Zones - <a href="https://www.openzonemap.com" target="__blank">Open Zone Map</a></p>
              </section>
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col>
              <div class="logo">
                <b-img class="logo-img" :src="aglogo"></b-img>
              </div>
            </b-col>
          </b-row> -->
        </b-col>
        <b-col
          class="text-center d-none d-md-block" 
          align-v="center"
          sm="5" 
          order="1" 
          order-sm="12"
        >
          <b-img class="map-img" :src="image"></b-img>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import image from '../assets/scm-square.png';
//import aglogo from '../assets/Logo-ag.png';
import aglogo2x from '../assets/Logo-ag@2x.png';
import toggleBarImage from "../assets/toggle-bar.png";
import { BIconArrowLeftShort}  from 'bootstrap-vue';
import TypeSimple from "../components/features/TypeSimple.vue";
import TypeCombo from "../components/features/TypeCombo.vue";
export default {
  components: {
    BIconArrowLeftShort: BIconArrowLeftShort,
    TypeSimple: TypeSimple,
    TypeCombo: TypeCombo
  },
  data() {
    return {
      image: image,
      //aglogo: aglogo,
      aglogo2x: aglogo2x,
      toggleBarImage: toggleBarImage,
      hash: this.$route.hash
    }
  },
  methods: {
    navBack() {
      this.$router.push('/map');
    },
    scrollToAnchorPoint(refName) {
      const el = this.$refs[refName];
      el.scrollIntoView({ behavior: "smooth" })
    }
  },
  mounted() {
    this.$gtm.trackView("About", "/about");
    this.$nextTick(function() {
      if (this.hash) {
        const refName = this.hash.replace("#", "");
        this.scrollToAnchorPoint(refName);
      }
    })
  }
}
</script>
<style lang="scss" scopped>
.about {
  margin-top: 10vh;
  //height: 100vh;
  overflow-y: hidden;
  background-color: #f7f7f7;
  font-family: Cabin, Medium;
  .back {
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    .back-link {
      color: #4e4e4e;
      font-size: 16px;
      svg {
        color: #2B72D8;
        transform: scale(1.5);
      }
    }
    .back-link:hover {
      color: #2B72D8;
      cursor: pointer;
    }
  }
  .title {
    color: #2B72D8;
    font-size: 31px;
    padding-left: 1rem;
    width: 70%;
    height: auto;
    line-height: 115%;
    margin-bottom: 1.5rem;
  }
  @media only screen and (max-width: 800px) {
    .title {
      font-size: 28px;
    }
  }
  @media only screen and (max-width: 600px) {
    .title {
      font-size: 26px;
      width: 360px;
    }
  }
  @media only screen and (max-width: 400px) {
    .title {
      font-size: 24px;
      width: 360px;
    }
  }
  @media only screen and (max-width: 320px) {
    .title {
      font-size: 22px;
    }
  }
  .content-column {
    section {
      border-top: 1px solid #4e4e4e;
      padding-top: 5vh;
    }
    section:nth-child(1) {
      border-top: unset;
      padding-top: unset;
    }
    section#how-to-use-this-map {
      margin-bottom: 1rem;
      div {
        text-align: center;
        img {
          height: 200px;
          width: auto;
        }
      }
    }
    h1, h2 {
      color: #2B72D8;
      padding-left: 1rem;
      margin-top: 1rem;
    }
    h3 {
      padding-left: 1rem;
      color: #4e4e4e;
      font-size: 16px;
    }
    .anchor-element {
      padding-top: 5vh;
    }
  }
  .about-text {
    color: #4e4e4e;
    padding-left: 1rem;
    font-size: 16px;
    padding-right: 1rem;
    margin-bottom: 1.5rem;
  }
  .disclaimer {
    font-style: italic;
  }
  ul {
    list-style: none;
    li {
      color: #4e4e4e;
      font-size: 16px;
    }
  }
  ul li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #2B72D8; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }
  div.text-center {
    position: relative;
    .map-img {
      position: fixed;
      right: 8vw;
      width: 450px;
      height: 450px;
      object-fit: fill;
      //padding-top: 1rem;
      //padding-bottom: 1rem;
      margin-bottom: 1.5rem;
    }
  }
  @media only screen and (max-width: 400px) {
    .map-img {
      width: 250px;
      height: 250px;
    }
  }
  .logo-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    margin-bottom: 1.5rem;
  }
  @media only screen and (min-width: 800px) {
    .logo-img {
      width: 50%;
    }
  }
}
#what-are-startup-cities > p:nth-child(3),
#what-are-startup-cities > p:nth-child(4) {
  margin-bottom: unset;
}

</style>