<template>
  <div 
    class="outer" 
    v-bind:style="{ background: outerColor }">
    <div 
      class="inner" 
      v-bind:style="{ background: innerColor}"></div>
  </div>
</template>
<script>
export default {
  props: {
    innerColor: {
      type: String,
      required: true
    },
    outerColor: {
      type: String,
      required: true
    }
  },
  data() {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
div {
  div.outer {
    border-radius: 50%;
    height: 0.5rem;
    width: 0.5rem;
    display: inline-flex;
    position: relative;
    div.inner {
      border-radius: 50%;
      height: 0.25rem;
      width: 0.25rem;
      display: inline-flex;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>